import React, { useEffect, useState } from "react";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { useNavigate } from "react-router-dom";
import { testEmail } from "../../../api/TestEmail";
import { getEmailTemplates } from "../../../api/GetEmailTemplates";
import ChooseDonorListModal from "./ChooseDonorListModal";
import classes from "./EmailTemplateModal.module.css";
import { useSelector } from "react-redux";
import { urlifyName } from "../../shared/Utils/PageUrl";

function EmailTemplateModal({
  isOpen,
  onClose,
  campaignId,
  nonprofitName,
  animalName,
  nonprofitId,
}) {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [url, setUrl] = useState("");
  const [placeholders, setPlaceholders] = useState({});
  const [subjectPlaceholders, setSubjectPlaceholders] = useState({});
  const [localUserData, setLocalUserData] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [testEmailError, setTestEmailError] = useState(""); // Error message for test email
  const [sendEmailError, setSendEmailError] = useState(""); // Error message for send email
  const navigate = useNavigate();
  const link = `https://www.sponsorapet.org/campaigns/${urlifyName(
    nonprofitName
  )}/${urlifyName(animalName)}/${campaignId}`;

  const staticPlaceholders = {
    "[Link]": link,
    "[Animal's Name]": animalName,
    "[Your Organization's Name]": nonprofitName,
  };
  const [inputValues, setInputValues] = useState({});
  const [subjectInputValues, setSubjectInputValues] = useState({});
  const userData = useSelector((state) => state.userData.userData);

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  // Donor List Modal
  const [isChooseDonorListModalOpen, setIsChooseDonorListModalOpen] =
    useState(false);

  useEffect(() => {
    const getTemplates = async () => {
      if (isOpen) {
        const templatesData = await getEmailTemplates();
        setTemplates(templatesData);
      }
    };

    if (isOpen) {
      getTemplates();
    }
  }, [isOpen]);

  useEffect(() => {
    const selectedTemplate = templates.find(
      (template) => template.id === selectedTemplateId
    );
    if (selectedTemplate) {
      extractPlaceholders(selectedTemplate); // Pass the template directly
    }
  }, [selectedTemplateId, templates]);

  const extractPlaceholders = (template) => {
    let allPlaceholders = {};
    const matches = template.body.match(/\[([^\]]+)\]/g) || [];
    matches.forEach((match) => {
      // Skip static placeholders
      if (
        !staticPlaceholders.hasOwnProperty(match) &&
        match !== "[Donor's Name]"
      ) {
        allPlaceholders[match] = "";
      }
    });
    setPlaceholders(allPlaceholders); // This sets only the dynamic placeholders

    let subPlaceholders = {};
    const subMatches = template.subject.match(/\[([^\]]+)\]/g) || [];
    subMatches.forEach((match) => {
      // Skip static placeholders
      if (
        !staticPlaceholders.hasOwnProperty(match) &&
        match !== "[Donor's Name]"
      ) {
        subPlaceholders[match] = "";
      }
    });
    setSubjectPlaceholders(subPlaceholders);
  };

  function escapeRegExp(text) {
    return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  const handleInputChange = (placeholder, value, isSubject = false) => {
    if (isSubject) {
      setSubjectInputValues((prevValues) => ({
        ...prevValues,
        [placeholder]: value,
      }));
      setSubjectPlaceholders((prev) => ({
        ...prev,
        [placeholder]: value,
      }));
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [placeholder]: value,
      }));
      setPlaceholders((prev) => ({
        ...prev,
        [placeholder]: value,
      }));
    }
  };

  const handleInputBlur = (placeholder, isSubject = false) => {
    if (isSubject) {
      setSubjectPlaceholders((prev) => ({
        ...prev,
        [placeholder]: subjectInputValues[placeholder],
      }));
    } else {
      setPlaceholders((prev) => ({
        ...prev,
        [placeholder]: inputValues[placeholder],
      }));
    }
  };

  useEffect(() => {
    setInputValues(placeholders);
  }, [placeholders]);

  useEffect(() => {
    setSubjectInputValues(subjectPlaceholders);
  }, [subjectPlaceholders]);

  const handleTemplateSelect = (templateId) => {
    if (selectedTemplateId === templateId) {
      setSelectedTemplateId(null);
      setPlaceholders({}); // Clear placeholders if deselecting
    } else {
      setSelectedTemplateId(templateId);
      const selectedTemplate = templates.find(
        (template) => template.id === templateId
      );
      if (selectedTemplate) {
        extractPlaceholders(selectedTemplate); // Directly pass the selected template
      }
    }
  };

  if (!isOpen) return null;

  const formatBodyWithNewlines = (
    bodyText,
    link,
    animalName,
    nonprofitName,
    isSubject = false
  ) => {
    let formattedText = bodyText.replace(/\[([^\]]+)\]/g, (match, p1) => {
      // List of placeholders to exclude from styling
      const excludeList = [
        "Donor's Name",
        "Link",
        "Animal's Name",
        "Your Organization's Name",
      ];

      // Check if the current placeholder is in the exclude list
      if (excludeList.includes(p1)) {
        // If it is, return it unchanged
        return match;
      } else {
        // Otherwise, wrap it in a span with #ff8c00 color
        return `<span style="color: #ff8c00;">${match}</span>`;
      }
    });

    // Then replace specific placeholders with their values, preserving the #ff8c00 color by replacing within the span.
    formattedText = formattedText
      .replace(/\[Link\]/g, link)
      .replace(/\[Animal's Name\]/g, animalName)
      .replace(/\[Your Organization's Name\]/g, nonprofitName);

    if (isSubject) {
      Object.keys(subjectPlaceholders).forEach((placeholder) => {
        const regex = new RegExp(
          escapeRegExp(`<span style="color: #ff8c00;">${placeholder}</span>`),
          "g"
        );
        if (subjectPlaceholders[placeholder]) {
          const replacement = `<span style="color: #ff8c00;">${subjectPlaceholders[placeholder]}</span>`;
          formattedText = formattedText.replace(regex, replacement);
        }
      });
    } else {
      // Process dynamic placeholders, if any, that are not among the static ones defined above
      Object.keys(placeholders).forEach((placeholder) => {
        const regex = new RegExp(
          escapeRegExp(`<span style="color: #ff8c00;">${placeholder}</span>`),
          "g"
        );
        if (placeholders[placeholder]) {
          const replacement = `<span style="color: #ff8c00;">${placeholders[placeholder]}</span>`;
          formattedText = formattedText.replace(regex, replacement);
        }
      });
    }

    return formattedText.split("\n").map((line, index, array) => (
      <React.Fragment key={index}>
        {line
          .split(new RegExp('(<span style="color: #ff8c00;">.*?</span>)', "g"))
          .map((part, partIndex) =>
            part.startsWith('<span style="color: #ff8c00;">') ? (
              <span
                key={partIndex}
                dangerouslySetInnerHTML={{ __html: part }}
              />
            ) : (
              part
            )
          )}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const createEmailJson = () => {
    const selectedTemplate = templates.find((t) => t.id === selectedTemplateId);

    if (!selectedTemplate) return;

    let formattedSubject = selectedTemplate.subject
      .replace(/\[Link\]/g, link)
      .replace(/\[Animal's Name\]/g, animalName)
      .replace(/\[Your Organization's Name\]/g, nonprofitName);

    let formattedBody = selectedTemplate.body
      .replace(/\[Link\]/g, link)
      .replace(/\[Animal's Name\]/g, animalName)
      .replace(/\[Your Organization's Name\]/g, nonprofitName);

    Object.keys(placeholders).forEach((placeholder) => {
      const regex = new RegExp(escapeRegExp(placeholder), "g");
      formattedBody = formattedBody.replace(
        regex,
        inputValues[placeholder] || ""
      );
    });

    Object.keys(subjectPlaceholders).forEach((placeholder) => {
      const regex = new RegExp(escapeRegExp(placeholder), "g");
      formattedSubject = formattedBody.replace(
        regex,
        subjectInputValues[placeholder] || ""
      );
    });

    const emailTemplateJson = {
      subject: formattedSubject,
      body: formattedBody,
      email: localUserData.email,
      name: selectedTemplate.name,
    };

    return emailTemplateJson;
  };

  const handleSendTestEmail = async () => {
    setTestEmailError(""); // Reset test email error message
    const data = createEmailJson();
    try {
      const response = await testEmail(data);
      if (response.status === 200) {
        setEmailSent(true);
      }
    } catch (error) {
      console.error("Test email error: ", error);
      const errMsg = errorHandler(error);
      setTestEmailError(errMsg);
    }
  };

  return (
    <>
      {isOpen && (
        <div className={classes.email_modal_overlay} onClick={onClose}></div> // Overlay that darkens the background
      )}
      {isOpen && (
        <>
          <div className={classes.modal_email_template}>
            <button onClick={onClose}>X</button>
            <h2 className="font-semibold text-3xl mb-4">
              Select Email Template
            </h2>
            <ul>
              {templates &&
                templates.map((template) => (
                  <li
                    className={classes.template_box}
                    key={template.id}
                    onClick={() => handleTemplateSelect(template.id)}
                  >
                    <h4 className="m-0 font-bold">{template.name}</h4>
                    {selectedTemplateId === template.id && (
                      <>
                        <div className="text-left">
                          <div className={classes.preview_heading}>
                            <h3 className="font-semibold text-xl mb-4">
                              Email Preview
                            </h3>
                          </div>
                          <h4 className="font-bold text-orange">
                            Email Subject:
                          </h4>
                          <p className="text-black">
                            {formatBodyWithNewlines(
                              template.subject,
                              link,
                              animalName,
                              nonprofitName,
                              true
                            )}
                          </p>
                          <h4 className="font-bold mt-8 text-orange">
                            Email Body:
                          </h4>
                          <p className="text-black">
                            {formatBodyWithNewlines(
                              template.body,
                              link,
                              animalName,
                              nonprofitName
                            )}
                          </p>
                        </div>
                        {/* <div>
												<h4>Modify Email Subject</h4>
												{Object.keys(subjectPlaceholders)
													.filter(placeholder => !staticPlaceholders.hasOwnProperty(placeholder)) // Only dynamic placeholders
													.map((placeholder, index) => (
														<div className='label-container' key={index}>
															<label style={{color: '#ff8c00'}}>{placeholder.replace(/\[|\]/g, '')}</label>
															<input
																type="text"
																value={subjectInputValues[placeholder] || ''}
																onClick={(e) => e.stopPropagation()}
																onChange={(e) => handleInputChange(placeholder, e.target.value, true)}
																onBlur={() => handleInputBlur(placeholder, true)}
															/>
														</div>
												))}
											</div> */}
                        <div>
                          <h4 className="font-bold mt-4 text-xl">
                            Modify Email Body
                          </h4>
                          {Object.keys(placeholders)
                            .filter(
                              (placeholder) =>
                                !staticPlaceholders.hasOwnProperty(placeholder)
                            ) // Only dynamic placeholders
                            .map((placeholder, index) => (
                              <div
                                className={classes.label_container}
                                key={index}
                              >
                                <label className="text-[#FF8C00]">
                                  {placeholder.replace(/\[|\]/g, "")}
                                </label>
                                <input
                                  type="text"
                                  value={inputValues[placeholder] || ""}
                                  onClick={(e) => e.stopPropagation()}
                                  onChange={(e) =>
                                    handleInputChange(
                                      placeholder,
                                      e.target.value
                                    )
                                  }
                                  onBlur={() => handleInputBlur(placeholder)}
                                />
                              </div>
                            ))}
                          {emailSent && localUserData && (
                            <p>Test email sent to {localUserData.email}</p>
                          )}
                          {testEmailError && (
                            <p className="text-[var(--default-red)]">
                              {testEmailError}
                            </p>
                          )}{" "}
                          {/* Display test email error */}
                          <div className={classes.action_buttons}>
                            <button
                              className={classes.submit_email}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSendTestEmail();
                              }}
                            >
                              Send Test Email To Yourself
                            </button>

                            <button
                              className={classes.submit_email}
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsChooseDonorListModalOpen(true);
                              }}
                            >
                              Choose Donor List
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </li>
                ))}
            </ul>
          </div>
          {isChooseDonorListModalOpen && (
            <div
              className={classes.choose_overlay}
              onClick={() => setIsChooseDonorListModalOpen(false)}
            >
              <ChooseDonorListModal
                isOpen={isChooseDonorListModalOpen}
                onClose={() => setIsChooseDonorListModalOpen(false)}
                onSelect={(selection) => {
                  setIsChooseDonorListModalOpen(false);
                }}
                createEmailJson={createEmailJson}
                campaignId={campaignId}
                nonprofitId={nonprofitId}
                setShowSuccessMessage={setShowSuccessMessage}
                setSendEmailError={setSendEmailError}
                sendEmailError={sendEmailError}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default EmailTemplateModal;
